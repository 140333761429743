import React, { useContext, useState, useEffect } from 'react'
import { Typography, withStyles, Grid, Button, Box } from '@material-ui/core'
import {
  AuthContext,
  PaddedContainer,
  SectionTitle,
  ScoringSlider,
  Thumb,
  FormikRichTextEditor,
} from 'gatsby-components'
import { Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from 'react-i18next'
import { Field, Form } from 'formik'
import { useMutation } from 'graphql-hooks'
import get from 'lodash/get'
import classnames from 'classnames'
import { getAssessmentParts } from 'efqm-theme/assessments/getAssessmentParts'
import { getAssessmentId } from '../utils/url'
import { filterOldScores } from '../utils/filter-old-scores'
import {
  getShallowAssessmentData,
  getAssessmentCriterionData,
  upsertAssessmentCriterionDataMutation,
  upsertAssessmentCriterionWeightingMutation,
} from '../queries'
import SEO from '../components/SEO'
import FileList from '../components/FileList'
import AssessmentPillars from '../components/AssessmentPillars'
import AutoSaveWatchFormik from '../components/AutoSaveWatchFormik'
import SaveChip from '../components/SaveChip'
import { assessmentInProgress } from '../utils/assessment-status'
import useAuthorizedWatch from '../hooks/useAuthorizedWatch'
import { limits } from '../config'

const criterionOverviewLimits = limits.criterion.overview

function CriterionTemplate({
  theme,
  classes,
  pageContext: {
    assessment: contextAssessment,
    pillar: contextPillar,
    criterion: contextCriterion,
    pillarColor,
    pillarColors,
    criterionNumber,
  },
  location,
}) {
  const assessmentId = getAssessmentId(location)
  const { getUserTokenData } = useContext(AuthContext)
  const { isContributor, userId } = getUserTokenData(assessmentId)

  const { t, i18n } = useTranslation()
  const { assessment, pillar, criterion } = getAssessmentParts(
    contextAssessment.key,
    i18n,
    contextPillar,
    contextCriterion
  )

  const { data: assessmentData } = useAuthorizedWatch(
    getShallowAssessmentData,
    { id: assessmentId },
    {
      onPreFetch: variables => !!variables.id,
      onFetch: fetchData =>
        filterOldScores(assessment, fetchData.assessment_by_pk),
    }
  )

  const {
    data: assessmentCriterionData,
    fetchedTimestamp,
    loading,
    isPreFetch,
    refetch: fetchAssessmentCriterionData,
  } = useAuthorizedWatch(
    getAssessmentCriterionData,
    {
      assessmentId,
      pillarKey: pillar.key,
      criterionKey: criterion.key,
    },
    {
      onPreFetch: variables => !!variables.assessmentId,
    }
  )

  const [upsertAssessmentData] = useMutation(
    upsertAssessmentCriterionDataMutation
  )
  const [upsertAssessmentWeighting] = useMutation(
    upsertAssessmentCriterionWeightingMutation
  )

  async function handleFormSubmit(values, { setSubmitting }) {
    try {
      await upsertAssessmentData({
        variables: {
          input: {
            assessment_id: assessmentId,
            pillar_key: pillar.key,
            criterion_key: criterion.key,
            data: values,
          },
        },
      })
    } finally {
      setSubmitting(false)
    }
  }

  const criteriaExists =
    assessmentCriterionData &&
    assessmentCriterionData.assessment_criterion_data_by_pk
  const formInitialValues = criteriaExists
    ? assessmentCriterionData.assessment_criterion_data_by_pk.data
    : { summary: '' }
  const canEditAndUpload =
    isContributor &&
    assessmentInProgress(get(assessmentCriterionData, 'assessment_by_pk'))

  const { weightRules } = criterion

  const [weightingValues, setWeightingValues] = useState(null)

  if (weightRules && weightingValues === null && assessmentCriterionData) {
    const weighting = assessmentCriterionData.assessment_criterion_weighting.find(
      weight => weight.criterion_key === criterion.key
    )

    if (weighting && weighting.weighting_values) {
      setWeightingValues(weighting.weighting_values)
    } else {
      setWeightingValues(
        criterion.parts.reduce(
          (a, { weight }) => ({
            ...a,
            [weight.key]:
              weightRules && weightRules.sliderDefaultValue
                ? weightRules.sliderDefaultValue
                : (weightRules && weightRules.totalsTo
                    ? weightRules.totalsTo
                    : 100) / criterion.parts.length,
          }),
          {}
        )
      )
    }
  }

  const hasCustomWeighting = weightRules && assessment.matrixType !== 'basic'

  const [partToCriterion, setPartToCriterion] = useState(null)
  const [partToParts, setPartToParts] = useState(null)
  const [
    partToCurrentCriteriaEquivalent,
    setPartToCurrentCriteriaEquivalent,
  ] = useState(null)

  useEffect(() => {
    if (!hasCustomWeighting) return

    const newPartToCriterion = {}
    const newPartToParts = {}
    const newPartToCurrentCriteriaEquivalent = {}

    assessment.pillars.forEach(pillar => {
      pillar.criteria.forEach(criterion => {
        const criterionKey = criterion.key
        const parts = []

        criterion.parts.forEach(part => {
          if (part.weight) {
            const { key: pillarKey, setWeightFor } = part.weight

            parts.push(pillarKey)
            newPartToParts[pillarKey] = parts
            newPartToCriterion[pillarKey] = criterionKey
            if (setWeightFor) {
              setWeightFor.forEach(weightKey => {
                newPartToCurrentCriteriaEquivalent[weightKey] = pillarKey
              })
            }
          }
        })
      })
    })

    setPartToCriterion(newPartToCriterion)
    setPartToParts(newPartToParts)
    setPartToCurrentCriteriaEquivalent(newPartToCurrentCriteriaEquivalent)
  }, [])

  const mapWeighting = partKey => {
    const res = {}

    if (partToParts && partToCurrentCriteriaEquivalent) {
      partToParts[partKey].forEach(key => {
        res[key] = weightingValues[partToCurrentCriteriaEquivalent[key]]
      })
    }

    return res
  }

  const weightTotal = () =>
    Object.values(weightingValues || {}).reduce((a, val) => a + val, 0)

  const isValidTotal =
    weightingValues && weightTotal() === (weightRules.totalsTo || 100)

  const handleWeightUpsert = partKeys => async () => {
    if (isValidTotal) {
      await Promise.all(
        partKeys.map(partKey => {
          return upsertAssessmentWeighting({
            variables: {
              input: {
                assessment_id: assessmentId,
                criterion_key: partToCriterion[partKey],
                weighting_values: mapWeighting(partKey),
              },
            },
          })
        })
      )
    }
  }

  const hasSliders = !!criterion.parts.filter(
    // has custom weight & can set custom weight
    part => part.weight && part.weight.setWeightFor
  ).length

  if (loading && isPreFetch) {
    return (
      <div className={classes.root} data-testid="criterion">
        <PaddedContainer className={classes.paddedContainer}>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item>
              <Typography variant="h3">Loading...</Typography>
            </Grid>
          </Grid>
        </PaddedContainer>
      </div>
    )
  }

  const files = get(assessmentCriterionData, 'assessment_file', [])

  return (
    <div className={classes.root} data-testid="criterion">
      <SEO title={t(criterion.name)} />
      <PaddedContainer className={classes.paddedContainer}>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item>
            <Button
              component={Link}
              to={`/assessment/${assessment.key}#${assessmentId}`}
              variant="text"
              color="secondary"
            >
              ◀ {t('Assessment overview')}
            </Button>
          </Grid>
          <Grid item xs />
          <Grid item>
            <FileList
              assessmentId={assessmentId}
              userId={userId}
              pillar={pillar}
              criterion={criterion}
              files={files}
              canUpload={
                files.length < criterionOverviewLimits.uploads &&
                canEditAndUpload
              }
              canDelete={canEditAndUpload}
              onUploadComplete={fetchAssessmentCriterionData}
              fileSize={criterionOverviewLimits.fileSize}
            />
          </Grid>
        </Grid>
        <div className={classes.section}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <SectionTitle barColor={pillarColor}>
                <Grid container item justify="flex-start">
                  <Link
                    to={`/assessment/${assessment.key}#${assessmentId}`}
                    className={classes.pillarLink}
                  >
                    {t(pillar.name)}
                  </Link>
                  <span
                    className={classes.arrow}
                    style={{ color: pillarColor }}
                  >
                    ▶
                  </span>
                  <Grid item xs>
                    {t(criterion.name)}
                  </Grid>
                </Grid>
              </SectionTitle>
              {hasCustomWeighting && (
                <div className={classes.weightingSection}>
                  <Grid item>
                    <Typography variant="h3" className={classes.weightingTitle}>
                      {t('stakeholder weighting')}
                    </Typography>
                  </Grid>
                  {criterion.parts.map(
                    ({ weight: { key, name, setWeightFor } }, i) => {
                      const scoreValue =
                        weightingValues !== null ? weightingValues[key] : 0
                      return setWeightFor ? (
                        <Grid
                          item
                          className={classes.sliders}
                          key={`${key}-weight`}
                        >
                          <Typography variant="h4" gutterBottom>
                            {`${criterionNumber}.${i + 1} ${t(name)}`}
                          </Typography>
                          <ScoringSlider
                            value={scoreValue}
                            color={scoreValue ? 'secondary' : null}
                            step={weightRules.sliderStep || 10}
                            max={weightRules.max}
                            min={weightRules.min}
                            tickCount={4}
                            onChange={(e, value) => {
                              const newTotal =
                                weightTotal() - weightingValues[key] + value

                              if (newTotal <= (weightRules.totalsTo || 100)) {
                                setWeightingValues({
                                  ...weightingValues,
                                  [key]: value,
                                })
                              }
                            }}
                            onChangeCommitted={handleWeightUpsert(setWeightFor)}
                            dontUseSliderInternalState={true}
                            disabled={
                              get(
                                assessmentCriterionData,
                                'assessment_by_pk.status'
                              ) !== 'in-progress'
                            }
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          container
                          key={`${key}-weight`}
                          justify="space-between"
                        >
                          <Grid item xs={10} className={classes.weightingTitle}>
                            <Typography variant="h4" gutterBottom>
                              {`${criterionNumber}.${i + 1} ${t(name)}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Thumb
                              value={scoreValue}
                              className={classes.thumb}
                            />
                          </Grid>
                        </Grid>
                      )
                    }
                  )}
                  <Grid container spacing={1} justify="space-between">
                    <Grid
                      item
                      className={classnames({
                        [classes.totalComponentSliders]: hasSliders,
                        [classes.totalComponentNoSliders]: !hasSliders,
                      })}
                    >
                      <Typography
                        variant="h2"
                        gutterBottom
                        color={
                          // weight values === null prevents red flashing on load
                          isValidTotal || weightingValues === null
                            ? 'primary'
                            : 'error'
                        }
                      >
                        {t('Total')}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      className={classnames({
                        [classes.totalComponentSliders]: hasSliders,
                        [classes.totalComponentNoSliders]: !hasSliders,
                      })}
                    >
                      <Typography
                        variant="h2"
                        gutterBottom
                        color={
                          isValidTotal || weightingValues === null
                            ? 'primary'
                            : 'error'
                        }
                      >
                        {`${weightTotal()}/${weightRules.totalsTo || 100}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  {!hasSliders && (
                    <>
                      <Grid item>
                        <Typography
                          variant="h3"
                          className={classes.weightingTitle}
                        >
                          {t('scoring weight')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          className={classes.weightExplanation}
                        >
                          {t(
                            'Stakeholder weighting has been applied from Criteria 3. If you would like to change these values you can do so there.'
                          )}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </div>
              )}
            </Grid>
            <Grid item xs>
              <Typography component="div">
                <ReactMarkdown
                  className={classes.description}
                  source={t(criterion.description)}
                />
              </Typography>
              <Button
                component={Link}
                to={`${location.pathname}/1#${assessmentId}`}
                variant="contained"
                color="secondary"
                className={classes.section}
                disabled={weightRules && !isValidTotal}
              >
                {t('Enter')}&nbsp;{criterion.name}
              </Button>
              <AutoSaveWatchFormik
                initialValues={formInitialValues}
                initialValuesTimestamp={fetchedTimestamp}
                onSubmit={handleFormSubmit}
              >
                {({ saving }) => (
                  <Form>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      className={classes.section}
                    >
                      <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom>
                          {t('summary')}&nbsp;{criterion.name}
                        </Typography>
                        <Field
                          name="summary"
                          component={FormikRichTextEditor}
                          noDebounce={true}
                          isAssessBase={true}
                          disabled={!canEditAndUpload}
                          lang={i18n.language}
                          maxWordCount={criterionOverviewLimits.words}
                        />
                      </Grid>
                      <Grid item>
                        <Box
                          component="div"
                          visibility={
                            // box keeps the footer from shifting down (reserves space when hidden)
                            canEditAndUpload && (criteriaExists || saving)
                              ? 'visible'
                              : 'hidden'
                          }
                        >
                          <div className={classes.saveStatus}>
                            <SaveChip dirty={saving} />
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </AutoSaveWatchFormik>
            </Grid>
          </Grid>
        </div>
        <AssessmentPillars
          assessment={assessment}
          assessmentData={assessmentData}
          pillarColors={pillarColors}
        />
      </PaddedContainer>
    </div>
  )
}

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  paddedContainer: {
    flex: 1,
  },
  section: {
    marginTop: theme.spacing(3),
  },
  arrow: {
    margin: theme.spacing(0, 0.5),
  },
  pillarLink: {
    color: 'black',
  },
  description: {
    '& p': {
      marginTop: 0,
    },
  },
  weightingSection: {
    backgroundColor: theme.palette.background.light,
    marginTop: theme.spacing(4),
  },
  weightingTitle: {
    padding: theme.spacing(2, 4),
  },
  sliders: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  totalComponentSliders: {
    margin: theme.spacing(2, 4),
  },
  totalComponentNoSliders: {
    margin: theme.spacing(2, 4, 0),
  },
  thumb: {
    color: theme.palette.text.hint,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(0.5),
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[2],
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-2),
  },
  weightExplanation: {
    fontSize: '16px',
    marginTop: theme.spacing(-1),
    padding: theme.spacing(0, 4, 2),
  },
  '@global': {
    '.ck-editor__editable_inline': {
      height: '164px',
    },
  },
})

export default withStyles(styles, { withTheme: true })(CriterionTemplate)
